import Loginpage from "./components/Loginpage";

function App() {
	return (
		<div className="App">
			<Loginpage />
		</div>
	);
}

export default App;
